import React from 'react';
import styled from '@emotion/styled';

import ArticlesSection from '../components/Articles/ArticlesSection';
import { AvailableSections } from '../models';

import CoursesSection from './Courses/CoursesSection';
import SuccessStories from './SuccessStories/SuccessStoriesSection';
import ActionSectionBottom from './ActionSectionBottom/ActionSectionBottom';
import ServiceSection from './Service/ServiceSection';
import CalendarEventsSliderSection from './CalendarEventsSlider/CalendarEventsSliderSection';
import ActionSectionMiddle from './ActionSectionMiddle/ActionSectionMiddle';
import Form from './Form';
import ProductSections from './Products/ProductsSection';

const FORM_TITLE =
    'Пожалуйста, заполните Заявку на диагностическую консультацию';
const FORM_SUBTITLE =
    '*Поля, помеченные звездочкой, обязательные для заполнения';
const FORM_BUTTON_TEXT = 'Бесплатная консультация';

interface SectionsProps {
    enabledSections: AvailableSections[];
    options?: { [key in keyof Partial<typeof AvailableSections>]: any };
}
const AVAILALBLE_SECTIONS: {
    [key in keyof typeof AvailableSections]: (props: any) => JSX.Element;
} = {
    courses: (props) => <CoursesSection title="Обучение" {...props} />,
    coursesLive: (props) => <CoursesSection live title="Обучение" {...props} />,
    successStrories: (props) => (
        <SuccessStories title="Истории успеха выпускников" {...props} />
    ),
    services: (props) => <ServiceSection title="Услуги" {...props} />,
    actionBlockMiddle: (props) => <ActionSectionMiddle {...props} />,
    market: (props) => <ProductSections title="Магазин" {...props} />,
    calendarEvents: (props) => (
        <CalendarEventsSliderSection
            title="Календарь предстоящих событий"
            {...props}
        />
    ),
    articles: (props) => <ArticlesSection title="Статьи" {...props} />,
    actionBlockBottom: (props) => <ActionSectionBottom {...props} />,
    form: (props) => (
        <Form
            title={FORM_TITLE}
            subtitle={FORM_SUBTITLE}
            buttonText={FORM_BUTTON_TEXT}
            {...props}
        />
    ),
};

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
`;

const Sections: React.FC<SectionsProps> = ({
    enabledSections = [],
    options = {},
}) => {
    return (
        <Container>
            {[...(enabledSections || [])]
                .sort(
                    (a, b) =>
                        (options[a]?.order || enabledSections.indexOf(a)) -
                        (options[b]?.order || enabledSections.indexOf(b))
                )
                .map((section) => {
                    const Component = AVAILALBLE_SECTIONS[section];

                    return (
                        <Component
                            key={section}
                            {...(options[section] || {})}
                        />
                    );
                })}
        </Container>
    );
};

export default Sections;
