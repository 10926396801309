import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { CourseModel } from '../../models';
import { FONT_GOTHAM_MEDIUM } from '../../styles/fonts';
import { FlexSpacer, RoundedButtonWithArrow } from '../commonStyled';
import CourseCard from '../CourseCard';
import { formatDate } from '../../utils';

interface CoursesItemProps {
    item: CourseModel;
}

const CoursesItem: React.FC<CoursesItemProps> = ({
    item: { target, image, subtitle, title, url, start_date },
}) => {
    const [currentDate, setCurrentDate] = useState<string>('');

    useEffect(() => {
        if (!start_date) {
            setCurrentDate(formatDate(new Date(), 'dd MMMM yyyy'));
        } else {
            setCurrentDate(start_date);
        }
    }, [start_date]);

    return (
        <Container target="_blank" rel="nofollow" href={url}>
            <CourseCardStyled image={image} subTitle={subtitle} title={title} />
            {currentDate && <StartDate>Старт: {currentDate}</StartDate>}
            <Description dangerouslySetInnerHTML={{ __html: target }} />
            <FlexSpacer />
            <RoundedButtonWithArrow as="div">Записаться</RoundedButtonWithArrow>
        </Container>
    );
};

const CourseCardStyled = styled(CourseCard)`
    margin-bottom: 40px;
`;

const StartDate = styled.div`
    color: #a60424;
    font-size: 18px;
    line-height: 26px;
    font-family: ${FONT_GOTHAM_MEDIUM};
`;

const Description = styled.div`
    color: #000000;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
    margin-bottom: 44px;
`;

const Container = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 100%;
    text-decoration: none;
    margin: 0 25px;

    &:hover {
        color: #000;
    }
`;

export default CoursesItem;
