import { useStaticQuery, graphql } from 'gatsby';

import { CourseModel } from '../models';
import { formatWpDate, parseWpDate } from '../utils';

export const useCourses = (live: boolean): CourseModel[] => {
    const { allWpCourse } = useStaticQuery(
        graphql`
            {
                allWpCourse(
                    sort: { fields: courses_acf___startDate, order: ASC }
                ) {
                    nodes {
                        courses_acf {
                            description
                            target
                            image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(width: 500)
                                    }
                                }
                                altText
                            }
                            start_date: startDate
                            start_date_raw: startDate
                            subtitle
                            title
                            url
                        }
                    }
                }
            }
        `
    );

    const nodes = allWpCourse?.nodes;
    let courses =
        nodes?.map((item) => ({
            ...item?.courses_acf,
            start_date: formatWpDate(
                item?.courses_acf?.start_date,
                'dd MMMM yyyy'
            ),
            start_date_raw: parseWpDate(item?.courses_acf?.start_date_raw),
        })) || [];

    if (live) {
        courses = courses.filter((v) => v.start_date);
    }

    return [
        ...courses.filter((item) => !item?.start_date),
        ...courses.filter((item) => item?.start_date),
    ];
};
