import React, { useEffect, CSSProperties } from 'react';
import styled from '@emotion/styled';
import Glide from '@glidejs/glide';

import { GlideBulletButton, device } from '../../commonStyled';
import arrowWhite from '../../../images/arrow-white.png';
import arrowBlack from '../../../images/arrow-black.png';

interface SectionWithSliderCenteredProps<TSliderItem> {
    sliderId: string;
    items: TSliderItem[];
    sliderElement: React.FC<{ item: TSliderItem }>;
    sliderOptions?: {
        perView?: number;
        gap?: number;
        bound?: boolean;
        breakpoints?: any;
    };
    maxWidth?: number;
    withShadows?: boolean;
    glideButtonsStyles?: CSSProperties;
}

const DEFAULT_PER_VIEW = 2;

function SectionWithSliderCentered<TSliderItem>({
    items,
    sliderElement: SlideElement,
    sliderId,
    sliderOptions = {},
    withShadows = false,
    maxWidth,
    glideButtonsStyles = {},
}: SectionWithSliderCenteredProps<TSliderItem>) {
    const isSliderDisabled =
        items.length <= (sliderOptions.perView || DEFAULT_PER_VIEW);

    const isOnlyOne = items.length === 1;

    const peek = withShadows
        ? { before: 0, after: 0 }
        : { before: 24, after: 24 };

    const { breakpoints, ...options } = sliderOptions;

    useEffect(() => {
        if (!isOnlyOne) {
            const glide = new Glide(`#${sliderId}`, {
                perView: DEFAULT_PER_VIEW,
                bound: true,
                gap: 50,
                breakpoints: {
                    1200: {
                        peek,
                        perView: 2,
                    },
                    900: {
                        peek,
                        perView: 1,
                        gap: 50,
                    },
                    ...(breakpoints || {}),
                },
                ...options,
            }).mount();
        }
    }, []);

    return isOnlyOne ? (
        <CenteredContainer>
            <SlideElement item={items[0]} />
        </CenteredContainer>
    ) : (
        <Container className={withShadows && 'sliderCentered'}>
            <SliderContainer maxWidth={maxWidth}>
                <div className="glide" id={sliderId}>
                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                            {items.map((item, index) => (
                                <li
                                    className="glide__slide"
                                    key={item?.title || index}
                                >
                                    <SlideElement item={item} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    {!isSliderDisabled && (
                        <>
                            <div data-glide-el="controls">
                                <SlideButton left data-glide-dir="<" />
                                <SlideButton right data-glide-dir=">" />
                            </div>
                        </>
                    )}
                    <div
                        className="glide__bullets"
                        data-glide-el="controls[nav]"
                        style={glideButtonsStyles}
                    >
                        {items.map((item, index) => (
                            <GlideBulletButton
                                key={index}
                                className="glide__bullet"
                                data-glide-dir={`=${index}`}
                            />
                        ))}
                    </div>
                </div>
            </SliderContainer>
        </Container>
    );
}

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SliderContainer = styled('div')<{ maxWidth: number }>`
    position: relative;
    width: ${({ maxWidth = 1100 }) => maxWidth}px;

    @media ${device.laptopMedium} {
        width: 100vw;
    }
`;

const SlideButton = styled('button')<{ right?: boolean; left?: boolean }>`
    position: absolute;
    top: 30%;
    ${({ left }) => `${left ? 'left' : 'right'}: -112px`};
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    background: none;
    height: 54px;
    width: 81px;
    border: 1px solid #a1a1a1;
    border-radius: 33px;

    transform: ${({ left }) => (left ? 'rotateY(180deg)' : '')};

    @media ${device.laptopLarge} {
        ${({ left }) => `${left ? 'left' : 'right'}: -75px`};
    }

    @media ${device.laptopMedium} {
        display: none;
    }

    &:before {
        content: '';
        height: 14px;
        width: 20px;
        background-image: url(${arrowBlack});
        background-size: cover;
    }

    &:hover {
        border: 1px solid #a60424;
        background-color: #a60424;
        cursor: pointer;

        &:before {
            background-image: url(${arrowWhite});
        }
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;

    .glide__slide {
        height: auto;
    }
`;

export default SectionWithSliderCentered;
