import React from 'react';

import { GlideBulletButton } from '../../commonStyled';

interface SectionWithSliderStickedSliderProps<TSliderItem> {
    items: TSliderItem[];
    sliderElement: React.FC<{ item: TSliderItem }>;
    sliderId: string;
    isSliderDisabled: boolean;
}

function SectionWithSliderStickedSlider<TSliderItem>({
    items,
    sliderId,
    sliderElement: SliderElement,
    isSliderDisabled,
}: SectionWithSliderStickedSliderProps<TSliderItem>) {
    return (
        <div
            className="glide SectionWithSliderStickedSlider"
            id={sliderId}
            style={{ height: '100%' }}
        >
            <div
                className="glide__track"
                data-glide-el="track"
                style={{ height: '100%' }}
            >
                <ul
                    className="glide__slides"
                    style={{ height: '100%', alignItems: 'center' }}
                >
                    {items.map((item, index) => (
                        <li
                            className="glide__slide"
                            style={{ height: 'auto' }}
                            key={item?.title || index}
                        >
                            <SliderElement item={item} />
                        </li>
                    ))}
                </ul>
            </div>
            {!isSliderDisabled && (
                <>
                    <div
                        className="glide__bullets"
                        data-glide-el="controls[nav]"
                    >
                        {items.map((item, index) => (
                            <GlideBulletButton
                                key={index}
                                className="glide__bullet"
                                data-glide-dir={`=${index}`}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default SectionWithSliderStickedSlider;
